import axios from "axios";
import {useDispatch} from "react-redux";
import {snackShow} from "./store/snackbarSlice";

function getFullEndpoint(endpoint, core = false){
  if(!endpoint.startsWith('/'))
    endpoint = '/' + endpoint;
  let api = core
    ? process.env.REACT_APP_CORE_HOST + process.env.REACT_APP_API
    : process.env.REACT_APP_RESTO_HOST + process.env.REACT_APP_API;


  // return process.env.REACT_APP_API + `/${endpoint}`;
  return `${api}${endpoint}`
}
function getImageFolder(){
  // return `/img`;
  return `${process.env.REACT_APP_RESTO_HOST}/img`;
}

const Api = {



  fileUrl: function(file){
    if(!file)
      return null;
    if(!file.startsWith('/'))
      file = '/' + file;
    let fileName = getImageFolder() + file;
    return fileName;
  },

  get: async function(endpoint, request = {},headers={}, core=false){
    endpoint = getFullEndpoint(endpoint, core);
    let params = (new URLSearchParams(request)).toString();
    // alert(endpoint);
    return await fetch(`${endpoint}?${params}`,
      {
        method:"get",
        headers: {
          ...headers,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-token':localStorage.getItem('token')
        }
      }
    )
  },
  post: async function(endpoint, data = {},headers = {}, core = false){
    endpoint = getFullEndpoint(endpoint, core);
    // alert(endpoint);
    return fetch(`${endpoint}`,
      {
        method:"post",
        headers: {
          ...headers,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-token':localStorage.getItem('token')
        },
        body: JSON.stringify(data)
      }
    )
  },
  put: async function(endpoint, data = {},headers = {}){
    return this.post(endpoint, data, {...headers, 'X-HTTP-Method-Override':"PUT"})
  },
  patch: async function(endpoint, data = {},headers = {}){
    return this.post(endpoint, data, {...headers, 'X-HTTP-Method-Override':"PATCH"})
  },
  _delete: async function(endpoint, data = {},headers = {}){
    return this.post(endpoint, data, {...headers, 'X-HTTP-Method-Override':"DELETE"})
  },
  postForm: async function(endpoint, form,headers = {},core = false){
    endpoint = getFullEndpoint(endpoint, core);
    return axios.post(endpoint, form,{
      headers: {
        ...headers,
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'x-token':localStorage.getItem('token')
      }
    });
  },
  putForm: async function(endpoint, form = {}, headers={}){
    return this.postForm(endpoint, form, {...headers,'X-HTTP-Method-Override':"PUT"})
  },
  patchForm: async function(endpoint, form = {}, headers={}){
    return this.postForm(endpoint, form, {...headers,'X-HTTP-Method-Override':"PATCH"})
  },
  toPhone: function(phone){
    let ph = phone.replace(/\D/, '');
    let matches = ph.match(/^(?:\+?7|8)?(\d{10,10})$/i)
    if(matches) {
      return matches[1];
    }
    return null;
  },
  formatPhone: function(phone){
    let ph = this.toPhone(phone);
    if(!ph)
      return null;
    let result = ph.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/i, '+7 ($1) $2-$3-$4')
    return result;
  },



  isSandbox: function(){
    return !!localStorage.getItem('sandbox');
  },
  setSandbox: function(on, style = null){
    localStorage.setItem('sandbox', on ? 'on' : '');
    if(style)
      this.setSandboxStyle(style);
  },
  setSandboxStyle: function(style){
    localStorage.setItem('sandboxStyle', JSON.stringify(style ?? {}));
  },
  getSandboxStyle: function() {
    if(!localStorage.getItem('sandboxStyle'))
      return this.getDefaultStyle();
    return JSON.parse(localStorage.getItem('sandboxStyle'));
  },
  getCurrentStyle: function() {
    if(!!localStorage.getItem('sandbox') && !!localStorage.getItem('sandboxStyle'))
      return JSON.parse(localStorage.getItem('sandboxStyle'));
    return this.getDefaultStyle();
    return {
      main: {
        background: '#121d1a',
        color: "#c19c7d",
        secondaryColor:'#888',
        tertiaryColor:'#9d854f',
      },
      goods: {
        background: '#121d1a',
        color: "#c19c7d",
        secondaryColor:'#888',
        tertiaryColor:'#9d854f',
        borderColor:'lightgray',
        borderWidth: 1,
        borderStyle:'solid'
      },
      card: {
        background: '#121d1a',
        color: "#c19c7d",
        secondaryColor:'#888',
        tertiaryColor:'#9d854f',
        borderColor:'lightgray',
        borderWidth: 1,
        borderStyle:'solid'
      },
      categoryCard: {
        background: '#121d1a',
        color: "#c19c7d",
        secondaryColor:'#888',
        tertiaryColor:'#9d854f',
        borderColor:'lightgray',
        borderWidth: 1,
        borderStyle:'solid'
      },
      categoryBlock: {
        background: '#121d1a',
        color: "#c19c7d",
        secondaryColor:'#888',
        tertiaryColor:'#9d854f',
        borderColor:'lightgray',
        borderWidth: 1,
        borderStyle:'solid'
      },
      textBlock: {
        background: '#121d1a',
        color: "#c19c7d",
        secondaryColor:'#888',
        tertiaryColor:'#9d854f',
        borderColor:'lightgray',
        borderWidth: 1,
        borderStyle:'solid'
      },
      goodsList: {},
      categoryList:{},
    }
  },
  logout:function (place = ''){
    localStorage.setItem('token', '');
    window.location.href = place ? `/p/${place}` : '/';
  },

  getDefaultStyle: function(){
    return {
      main:{
        background:'#F3F3F3',
        color:"#121d1a",
        secondaryColor:'#888',
        tertiaryColor:'#9d854f',
      },
      goods:{
        background:'#FFFFFF',
        color:"#121d1a",
        secondaryColor:'#888',
        tertiaryColor:'#9d854f',
        borderColor:'lightgray',
        borderWidth: 1,
        borderStyle:'solid'
      },
      card:{
        background:'#FFFFFF',
        color:"#121d1a",
        secondaryColor:'#888',
        tertiaryColor:'#9d854f',
        borderColor:'lightgray',
        borderWidth: 1,
        borderStyle:'solid'
      },
      categoryCard:{
        background:'#FFFFFF',
        color:"#121d1a",
        secondaryColor:'#888',
        tertiaryColor:'#9d854f',
        borderColor:'lightgray',
        borderWidth: 1,
        borderStyle:'solid'
      },
      categoryBlock:{
        background:'#FFFFFF',
        color:"#121d1a",
        secondaryColor:'#888',
        tertiaryColor:'#9d854f',
        borderColor:'lightgray',
        borderWidth: 1,
        borderStyle:'solid',
      },
      textBlock: {
        background: '#FFFFFF',
        color: "#121d1a",
        secondaryColor:'#888',
        tertiaryColor:'#9d854f',
        borderColor:'lightgray',
        borderWidth: 0,
        borderStyle:'solid'
      },
      carousel: {
        background: '#F3F3F3',
        color: "#121d1a",
        secondaryColor:'#888',
        tertiaryColor:'#9d854f',
        borderColor:'lightgray',
        borderWidth: 0,
        borderStyle:'solid'
      },
      carouselText: {
        background: '#FFFFFF',
        color: "#121d1a",
        secondaryColor:'#888',
        tertiaryColor:'#9d854f',
        borderColor:'lightgray',
        borderWidth: 0,
        borderStyle:'solid'
      },
      goodsList: {},
      categoryList:{},
    }
  },
  processStyle: function(style, block, individualBlock = null, id = null){
    if(this.isSandbox())
      style = this.getSandboxStyle();
    if(typeof style !== 'object')
      style = {};
    let styleBlock = {...(style[block] ?? this.getDefaultStyle()[block] ?? {})};
    if(individualBlock && id > 0){
      let styleList = {...(style[individualBlock] ?? {})};
      styleBlock = typeof styleList[id] === 'object' ? {...styleBlock,...(styleList[id])} : styleBlock;
    }
    if(styleBlock.bgImageLink)
      styleBlock.backgroundImage = `url('${this.fileUrl(styleBlock.bgImageLink)}')`;
    return styleBlock;
  }

}



export default Api;